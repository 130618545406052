export const Button = {
  baseStyle: {},
  variants: {
    header: {
      colorScheme: '#303056',
      borderRadius: '41px',
      padding: '10px 20px',
      border: '1px solid',
      color: '#303056',
      width: '100%',
    },
    footer: {
      colorScheme: '#FFFFFF',
      borderRadius: '41px',
      padding: '10px 20px',
      border: '1px solid',
      color: '#FFFFFF',
      width: '100%',
    },
    contact: {
      bgColor: 'rgba(3, 3, 4, 0.5)',
      borderRadius: '30px',
      padding: '15px 35px',
      color: 'rgba(3, 3, 4, 0.5)',
      width: '100%',
    },
  },
};
