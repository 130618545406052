export const styles = {
  layout: {
    bgGradient:
      'linear(130.02deg, rgba(232, 202, 251,0.85), rgba(138, 135, 193,0.85))',
    position: 'relative',
    minH: '100vh',
    zIndex: 1,
  },
  backgroundImage: {
    zIndex: '0',
    width: '100%',
  },
};
