import '@fontsource/roboto/500.css';

export const styles = {
  wrapper: {
    maxnWidth: '100%',
    width: '100vw',
    zIndex: 11,
  },
  headerNavText: {
    fontWeight: '500',
    fontSize: '14px',
    color: '#303056',
  },
};

