import { Button, Flex, Stack, Text } from '@chakra-ui/react';

import { Link } from 'gatsby';
import LogoIcon from '../../images/LogoIcon';
import MenuDrawer from '../menu-drawer';
import React from 'react';
import { styles } from './styles';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import '@fontsource/montserrat';

const Header = () => {
  const breakpoints = useBreakpoint();
  return (
    <Flex
      justifyContent={{ base: 'space-between', xl: 'center' }}
      height={{ base: '75px', xl: '100px' }}
      ml={{ base: '4', xl: '0' }}
      sx={styles.wrapper}
      zIndex={10}
      as='header'
    >
      <Flex alignSelf='flex-end'>
        <Link to='/'>
          <Flex
            alignSelf={{ base: 'initial', xl: 'flex-end' }}
            justifyContent={{ base: 'flex-start', xl: 'center' }}
            alignItems='center'
            w='264px'
            h='75px'
          >
            <LogoIcon />
            <Flex flexDirection='column' ml='2'>
              <Text
                lineHeight='22px'
                fontWeight='normal'
                fontSize='24px'
                color='white'
                mt='2'
              >
                DIGITALGOALS
              </Text>
              <Text
                fontWeight='normal'
                fontSize='17px'
                textAlign='center'
                color='white'
              >
                www.digitalgoals.net
              </Text>
            </Flex>
          </Flex>
        </Link>
      </Flex>
      {breakpoints.xl ? (
        <>
          <Stack direction='row' spacing='54px' pl='114px' mt='52.5px' h='16px'>
            <Link to='/hosting'>
              <Text sx={styles.headerNavText}>Hosting</Text>
            </Link>
            <Link to='/programming'>
              <Text sx={styles.headerNavText}>Programming</Text>
            </Link>
            <Link to='/consulting'>
              <Text sx={styles.headerNavText}>Consulting</Text>
            </Link>
            <Link to='/development'>
              <Text sx={styles.headerNavText}>Development</Text>
            </Link>
            <Link to='/our-projects'>
              <Text sx={styles.headerNavText}>Our projects</Text>
            </Link>
          </Stack>
          <Link to='/contact-us'>
            <Button variant='header' ml='47px' mt='40px' w='119px' h='41px'>
              Contact Us
            </Button>
          </Link>
        </>
      ) : (
        <Flex mr='8' alignSelf='center'>
          <MenuDrawer />
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
