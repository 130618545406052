import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { Link } from 'gatsby';
import MenuIcon from '../../images/MenuIcon';
import React from 'react';
import { styles } from '../header/styles';

function MenuDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Box ref={btnRef} onClick={onOpen}>
        <MenuIcon />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Navigation</DrawerHeader>
          <DrawerBody display='flex' flexDirection='column'>
            <Stack direction='column' spacing='4' h='16px'>
              <Link to='/hosting'>
                <Text fontSize='40px' sx={styles.headerNavText}>
                  Hosting
                </Text>
              </Link>
              <Link to='/programming'>
                <Text sx={styles.headerNavText}>Programming</Text>
              </Link>
              <Link to='/consulting'>
                <Text sx={styles.headerNavText}>Consulting</Text>
              </Link>
              <Link to='/development'>
                <Text sx={styles.headerNavText}>Development</Text>
              </Link>
              <Link to='/our-projects'>
                <Text sx={styles.headerNavText}>Our projects</Text>
              </Link>
              <Box>
                <Link to='/contact-us'>
                  <Button variant='header' w='119px' h='41px'>
                    Contact Us
                  </Button>
                </Link>
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default MenuDrawer;
