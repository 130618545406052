import * as style from './style.module.css';

import { ChakraUIProvider } from '../../context/chakra-provider';
import { Fade, Flex } from '@chakra-ui/react';
import Footer from '../footer';
import Header from '../header/index';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { styles } from './styles';
import '@fontsource/mulish/900.css';
import '@fontsource/mulish/700.css';
import '@fontsource/mulish/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/sora';
import '@fontsource/montserrat/900.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/poppins/900.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/400.css';

const Layout = ({ children }) => {
  return (
    <ChakraUIProvider>
      <Flex
        className={style.global}
        direction='column'
        sx={styles.layout}
        overflow='hidden'
      >
        <Fade in='true'>
          <StaticImage
            alt='background'
            placeholder='blurred'
            layout='fixed'
            src='../../images/bgImage.png'
            style={{ position: 'absolute', alignSelf: 'center', zIndex: -1 }}
          />
          <Header />
          <main style={{ zIndex: 20 }}>{children}</main>
          <Footer />
        </Fade>
      </Flex>
    </ChakraUIProvider>
  );
};

export default Layout;
