export const styles = {
  wrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    maxnWidth: '100%',
    width: '100vw',
    background: '#11082F',
  },
  footerTop: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    mb: '28px',
    mt: '18px',
  },
  footerBottom: {
    justifyContent: 'center',
    background: '#6F6F9E',
    alignItems: 'center',
    display: 'flex',
    w: '100%',
    h: '40px',
  },
  footerNavText: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '14px',
    color: 'white',
  },
};
