import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import React from 'react';
import theme from '../theme/theme';

export const ChakraUIProvider = ({ children }) => {

  return (
    <ChakraProvider theme={extendTheme({ ...theme })}>
      {children}
    </ChakraProvider>
  );
};
