import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='46'
      height='46'
      fill='none'
      viewBox='0 0 46 46'
    >
      <g fill='#fff' clipPath='url(#clip0_11_33)'>
        <path d='M33.3 43.4C30.2 45.1 26.7 46 23 46c-.6 0-1.1 0-1.7-.1 4.2-2.2 20-10.6 21-32.8 1.7 3.5 2.8 7.6 2.7 11.4-.2 4-1.4 7.9-3.7 11.3-2 3.2-4.8 5.8-8 7.6zm6.3-26.6C32.3 35.6 16.9 39.9 8.5 40.5c-1.9-1.7-3.5-3.7-4.7-5.9 21.2-.9 30.6-10.4 35.8-17.8zM1.6 18.8c.8-3.6 2.6-7 5-9.8 2.5-2.8 5.6-4.9 9.1-6.1 3.5-1.2 7.2-1.6 10.9-1 3.6.6 7.1 2.1 10 4.4l-3.2 4.1c-2.2-1.8-4.9-2.9-7.6-3.4-2.8-.5-5.7-.2-8.3.7-2.7.9-5.1 2.6-6.9 4.7-1.6 1.8-2.8 4-3.5 6.3-1.6.1-3.5.1-5.5.1z'></path>
        <path d='M2.5 31.9c11.4-.4 39.2-5 39.2-31.4C33.2 25.3 7.1 22.2 1.1 22.2c-.4 3.2.3 6.7 1.4 9.7z'></path>
      </g>
      <defs>
        <clipPath id='clip0_11_33'>
          <path fill='#fff' d='M0 0H46V46H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
