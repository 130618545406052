import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';

import { Link } from 'gatsby';
import LogoIcon from '../../images/LogoIcon';
import React from 'react';
import { styles } from './styles';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Footer = () => {
  const breakpoints = useBreakpoint();
  return (
    <Flex as='footer' minH='175px' sx={styles.wrapper} zIndex={10}>
      <Box sx={styles.footerTop}>
        <Flex
          alignSelf={{ base: 'initial', xl: 'flex-end' }}
          justifyContent={{ base: 'center', xl: 'center' }}
          alignItems='center'
          w='272px'
          h='90px'
        >
          <Link to='/'>
            <LogoIcon />
          </Link>
          <Flex flexDirection='column' ml='2'>
            <Link to='/'>
              <Text
                fontFamily='Montserrat'
                lineHeight='22px'
                fontWeight='normal'
                fontSize='25px'
                color='white'
                mt='2'
              >
                DIGITALGOALS
              </Text>
              <Text
                fontFamily='Montserrat'
                fontWeight='normal'
                fontSize='17px'
                textAlign='center'
                color='white'
              >
                www.digitalgoals.net
              </Text>
            </Link>
          </Flex>
        </Flex>
        {breakpoints.xl ? (
          <>
            <Stack direction='row' spacing='54px' pl='103px'>
              <Link to='/hosting'>
                <Text
                  sx={styles.footerNavText}
                  fontFamily='Roboto'
                  fontWeight='500'
                >
                  Hosting
                </Text>
              </Link>
              <Link to='/programming'>
                <Text sx={styles.footerNavText}>Programming</Text>
              </Link>
              <Link to='/consulting'>
                <Text sx={styles.footerNavText}>Consulting</Text>
              </Link>
              <Link to='/development'>
                <Text sx={styles.footerNavText}>Development</Text>
              </Link>
              <Link to='/our-projects'>
                <Text sx={styles.footerNavText}>Our projects</Text>
              </Link>
            </Stack>
            <Link to='/contact-us'>
              <Button variant='footer' ml='47px' w='119px' h='41px'>
                Contact Us
              </Button>
            </Link>
          </>
        ) : null}
      </Box>

      <Box sx={styles.footerBottom}>
        <Text fontFamily='Montserrat' fontSize='14px' color='#EEFEF7'>
          © {new Date().getFullYear()} DIGITALGOALS All rights reserved.
        </Text>
      </Box>
    </Flex>
  );
};

export default Footer;
