import React from 'react';

function MenuIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='45'
      height='26'
      fill='none'
      viewBox='0 0 45 26'
    >
      <rect width='45' height='6' fill='#fff' rx='3'></rect>
      <rect width='45' height='6' y='10' fill='#fff' rx='3'></rect>
      <rect width='45' height='6' y='20' fill='#fff' rx='3'></rect>
    </svg>
  );
}

export default MenuIcon;
